import React, { useEffect, createRef } from "react"
import LayoutHrbridReport from "../components/layouthybridreport"
import NavigationTeam from "../components/navigationteam"
import HybridReportForm from "../components/HybridReportForm"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import "../styles/hybrid.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import SEOHeader from "../components/seo-header"
import "lazysizes"
import HybridImage from "../img/people-meeting-seminar-office-concept.jpg"
export default function HybridReport() {
  return (
    <div>
      <SEOHeader
        title="A Comprehensive Report on How India Embraced Hybrid Work"
        description="Insights from Customers using GoFloaters Hybrid Workspace platform - WorqFlexi"
        socialURL="https://assets.gofloaters.com/socialimage/hybrid-report.jpg"
        pinterest="true"
      ></SEOHeader>
      <Helmet>
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
          defer
        ></script>
      </Helmet>
      <LayoutHrbridReport>
        <div className="hybridreport">
          <NavigationTeam />
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
            </div>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div className="TeamTextContent text-center">
                  <br></br>
                  <br></br>
                  <h3
                    style={{
                      lineHeight: "1",
                      padding: "0",
                      marginBottom: "15px",
                    }}
                  >
                    The State of Hybrid Work
                  </h3>
                  <h2
                    style={{
                      lineHeight: "1",
                      padding: "0",
                      marginBottom: "15px",
                      fontWeight: "600",
                    }}
                  >
                    A Comprehensive Report on
                  </h2>
                  <h1
                    style={{
                      fontSize: "3.2em",
                      color: "#4f9fc8",
                      marginBottom: "15px",
                      padding: "0",
                      lineHeight: "1",
                    }}
                  >
                    How India Embraced Hybrid Work
                  </h1>
                  <p>
                    Insights from Customers using GoFloaters Hybrid Workspace
                    platform - WorqFlexi
                  </p>
                  <br></br>
                  <a
                    onClick={() => scrollTo("#signup")}
                    className="teamGetStarted"
                    style={{ background: "rgb(79, 159, 200)", color: "#fff" }}
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <br></br>
                <img
                  data-src="https://assets.gofloaters.com/worqpass/hybrid-report-big.png"
                  alt="The State of Hybrid Work A Comprehensive Report on How India Embraced Hybrid Work"
                  className="lazyload img-responsive"
                ></img>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>

        <div style={{ padding: "10px 0", background: "#fff" }}>
          <div className="container">
            <div className="row  teamPadding TeamsWorkFromHome"></div>
          </div>
        </div>

        <div style={{ background: "#fff" }}>
          <div>
            <div className="container">
              <HybridReportForm />
            </div>
          </div>
        </div>

        <div style={{ padding: "10px 0", background: "#f7ffff" }}>
          <div className="container">
            <div className="row  teamPadding TeamsWorkFromHome">
              <div className="col-md-6 TeamsWorkFromHomePoint">
                <img
                  data-src="https://assets.gofloaters.com/clients/qdesq-logo.svg"
                  alt="officespacegotbetter"
                  className="lazyload"
                  width="160"
                ></img>
                <br /> <br />
                <p>
                  We gladly endorse, ‘The State of Hybrid Work' report by
                  GoFloaters. As India’s leading flex workspace platform, we’ve
                  been witnessing the same traction and adoption of hybrid work
                  across businesses and corporates. GoFloaters’s data driven
                  approach to highlight usage pattern across- cities, gender,
                  work timings and businesses are noteworthy.
                </p>
                <p style={{ fontSize: "1.1em" }}>
                  <b>
                    Paras Arora,<br></br> Founder & CEO-Qdesq
                  </b>
                </p>
                <br />
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-4 text-center TeamsWorkFromHomePoint">
                <img
                  data-src="https://assets.gofloaters.com/testimonial/paras-arora.png"
                  alt="officespacegotbetter"
                  className="lazyload img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "#fff", padding: "60px 0" }}>
          <div className="container">
            <div
              style={{
                background: "#4f9fc8",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={HybridImage}
                    className="img-responsive"
                    style={{ borderRadius: "10px" }}
                  ></img>
                </div>
                <div className="col-md-8">
                  <br></br>
                  <h2 style={{ color: "#fff" }}>
                    Teams from Chargebee, Wingify, GainCredit, Branch, inFeedo
                    and many more have mastered  hybrid work.
                  </h2>
                  <br></br>
                  <a
                    href="/blog/worqflexi-from-gofloaters-a-unified-hybrid-workplace-solution/"
                    style={{
                      background: "#f9d472",
                      color: "#000",
                      padding: "10px 15px",
                      borderRadius: "5px",
                      textDecoration: "none",
                    }}
                    target={"_blank"}
                  >
                    {" "}
                    Read How
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ background: "#fff", padding: "60px 0" }}>
          <div className="container">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-1 col-2">
                <img
                  src="https://assets.gofloaters.com/worqpass/center-arrows.svg"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-6 col-8 text-center">
                <h2 className="readyToHybrid">
                  Ready for<br></br> Hybrid-Remote for Teams?
                </h2>
              </div>
              <div className="col-md-1 col-2">
                {" "}
                <img
                  src="https://assets.gofloaters.com/worqpass/center-arrows.svg"
                  className="img-responsive rightimg"
                ></img>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div> */}
      </LayoutHrbridReport>
    </div>
  )
}
