import React, { useState, useEffect } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
const FooterHybrid = props => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <br></br>
            <img
              data-src="https://assets.gofloaters.com/logo.png"
              className="lazyload"
              width="230"
              height="65"
              alt="GoFloaters"
            ></img>
          </div>{" "}
          <div className="col-md-3">
            <ul className="links">
              <li className="col-md-6 noleft">
                <Link to="/worqflexi/">Solution </Link>
              </li>
              <li className="col-md-6 noleft">
                <Link to="/how-are-we-different/">Why GoFloaters</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul className="links">
              <li className="col-md-6 noleft">
                <Link to="/about-us/">About us</Link>
              </li>
              <li className="col-md-6 noleft">
                <a
                  href="https://virtualoffice.gofloaters.com/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Virtual Offices
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul className="links">
              <li className="col-md-6 noleft">
                <Link to="/meeting-spaces/">Locations</Link>
              </li>
              <li className="col-md-6 noleft">
                <Link to="/contact-us/">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <li>
                Email:{" "}
                <a href="mailto:support@gofloaters.com">
                  support@gofloaters.com
                </a>
              </li>
            </ul>
            <ul className="sociallinks">
              <li>
                <a
                  href="https://www.facebook.com/gofloatersindia"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="https://assets.gofloaters.com/facebook.png"
                    width="30"
                    height="30"
                    alt="GoFloaters Facebook"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/gofloatersapp"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    data-src="https://assets.gofloaters.com/instagram.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters Instagram"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/gofloaters/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    data-src="https://assets.gofloaters.com/linkedin.png"
                    width="30"
                    height="30"
                    className="lazyload"
                    alt="GoFloaters linkedin"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/gofloaters"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <img
                    data-src="https://assets.gofloaters.com/twitter.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters Twitter"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCy_PdgFKsHuZY_hZ2r8gQvg"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <img
                    data-src="https://assets.gofloaters.com/youtube.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters YouTube"
                  ></img>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2024 GoFloaters</p>
      </div>
    </footer>
  )
}

export default FooterHybrid
