import React from "react"
import "../assets/bootstrap.min.css"
import "../styles/style.scss"
import HeaderTeam from "./headerteam"
import FooterHybrid from "./footer-hybrid"
import "../styles/teams.scss"
const LayoutHrbridReport = props => {
  return (
    <div>
      <HeaderTeam />
      {props.children}
      <FooterHybrid />
    </div>
  )
}
export default LayoutHrbridReport
